import * as Sentry from '@sentry/browser'

const isSentryEnabled = process.env.MIX_SENTRY_DSN && ['production', 'staging'].includes(process.env.MIX_APP_ENV)

Sentry.init({
    enabled: isSentryEnabled,
    dsn: process.env.MIX_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: process.env.MIX_APP_ENV,
    tracesSampleRate: Number(process.env.MIX_SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: Number(process.env.MIX_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    replaysOnErrorSampleRate: Number(process.env.MIX_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
})

window.$sentry = {
    setUserId: id => {
        Sentry.configureScope(scope => scope.setUser({ id }))
    }
}

document.dispatchEvent(new CustomEvent('sentry:ready'))

if (isSentryEnabled) {
    console.log('Sentry initialized')
}
